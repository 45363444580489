.menuOptionen {
  text-align: left;
}

#filter {
  position: relative;
  /* top: 14%; */
  /* padding: 0.5%; */
}

#inhalt {
  position: relative;
  /* padding: 0.5%; */
}

#unsicherheitsintervall {
  position: relative;
  /* top: 26%; */
  /* padding: 0.5%; */
}

.einführung {
  font-size: 15px;
  /* border-radius: 1%; */
  /* width: 160px; */
}

.button-filter {
  margin-left: auto;
  margin-right: 0;
  width: 100%;
  font-size: 0.9rem;
  position: relative;
  left: 0px;
  /* border-radius: 5px; */
  border: 0px;
  /* margin: 3px 0px; */
  margin-bottom: 10px;
  /* padding: 4px 4px; */
  text-align: left;
}
.button-anzeige {
  margin-left: auto;
  margin-right: 0;
  width: 100%;
  font-size: 0.9rem;
  position: relative;
  left: 0px;
  border-radius: 5px;
  border: 0px;
  margin: 5px 0px;
  margin-bottom: 0px;
  padding: 6px 6px;
  padding-left: 10px;
  text-align: left;
}

.button-unsicherheitsintervall {
  margin-left: auto;
  margin-right: 0;
  width: 100%;
  font-size: 0.9rem;
  position: relative;
  left: 0px;
  border-radius: 5px;
  border: 0px;
  margin-top: 5px;
  /* margin-right: 20%; */
  margin-bottom: 0px;
  /* padding: 6px 6px; */
  text-align: center;
}

input[name="select-date"] {
  border: none;
  outline: 0px;
  background: none;

  width: 100%;
}

/* .BLlayout {
  padding-right: 30%;
} */

.btn-date {
  width: 100%;
}
/* .agelayout {
  padding-right: 50%;
} */
#age-select {
  width: 100%;
  border: none;
  outline: 0px;
  border-radius: 0.375rem;
  border-color: transparent;
  background-color: transparent;
  text-align: left;
  font-size: 0.9rem;
  padding-left: -2px;
  accent-color: grey;
}

input[name="größe"] {
  accent-color: grey;
  position: absolute;
  left: 85%;
}

input[name="unsicherheitsintervall"] {
  accent-color: grey;
  position: absolute;
  left: 85%;
}