/* Navigation Bar */
.navbar{
    width: 100%;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .container-fluid{
   text-align: left;
  }
  .navbar-brand{
    font-size: 2rem;
    font-weight: bold;
    color: black;  
  }
  .navbar-brand:hover{
    color: #586F7C;
  }
  .nav-item{
    padding: 0 18px;
    color: black;
  }
  .nav-link{
    font-size: 1.2rem;
    color: black;
    padding: 10px;
  }
  .nav-link:hover{
    color: #586F7C;
  }
  .navbar-toggler {
    border-color: #586F7C;
  }
  .navbar-toggler-icon {
    display: inline-block;
    width: 1,2em;
    height: 1,2em;
    vertical-align: middle;
    background-image: var(--bs-navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    
  }
  /* Sprache Dropdown */
  .btn-light:hover{
    background-color: transparent;
   color: black;
  }
  .dropdown-item:hover{
    background-color: rgb(64, 143, 243);
  }

  .navbar-toggler{
    position: absolute;
    right: 2%;
  }