/* Titel */
#title{
    width: 90%;
  }
  
  .title{
    position: relative;
    left: 70px;
  }
  .titel-text-main{
    position: relative;
      left: 35px;
    text-align: left;

  }
  .lineheader{
    background-color: black;
    height: 3px;
    width: 80px;
    position: absolute;
      left: -70px;
      top: 27px;
    margin-right: 20px;
  }

  .expbutton{
    width: 100%;
    margin-right: 5%;
  }

  .expbutton-expoff{
    width: 18%;
    position: relative;
     left: 79%;
    margin-right: 5%;
    border-radius: 5px;
    background-color: rgba(104, 162, 239, 0.432);
    border-color: rgba(134, 134, 134, 0.432);
    
   
  }
  
  .text-expoff {
    padding-top: 0.5rem ;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    margin-bottom: 0;
    border-radius: 5px;
  }
 


  /* Buttons */
 

  .rowbuttons{
    width: 100%;
    padding: 15px 15px 20px;
    border-radius: 5px;
    text-align: center;
    
  }
  
  .button{
    outline: black;
    color: black;
    font-size: 1.0rem;
    outline: 1px solid;
    
    
  }


  
  .button:hover{
    transform: sle(1.05);
  }

  .infobutton{
    height: 80px;
    border-radius: 5px;

  }
  .infobutton:hover{
        background-color: #ddd;
  }


  
  public int
  .text-fix {
    padding-top: 0.5rem ;
    padding-bottom: 0.3rem;
    font-size: 1.1rem;
    margin-bottom: 0;
  }

  .text-fixDP {
    padding-top: 0.5rem ;
    padding-bottom: 0.3rem;
    font-size: 1.1rem;
    margin-bottom: 0;
    font-weight: bold;
  }


  .text-var{
    padding-top: 0.3rem ;
    padding-bottom: 0.5rem;
    font-size: 1.0rem;
  }
  
  
  
  .breit{
    width: 70%;  
  }
  

  

  /* Text */
  #text{
    width: 100%;
    margin-top: 15px;
    margin-bottom: -25px;
  }
  .text-intro{
    width: 97%;
    margin-right: 25px;
    margin-left: 25px;
    margin-bottom: 30px;
    margin-bottom: 30px;
    text-align: left;
  }

  .text-introDP{
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
    margin-bottom: 30px;
    text-align: justify;
  }
  
  /* Chart */
  #chart{
    margin-right: 25px;
    margin-left: 25px;
  }

  .row-chart{
    height: 440px;
  }
  
  
  
  /*Tabelle*/
  .table{
    margin-right: 25px;
    margin-left: 25px;
    margin-bottom: 25px;
    float: left;
    width: 95%;
  }

  .btn-table{
    float: left;
    margin: 4px;
    
  }

  .collapse-table{
    float:left;
    width: 85%;
  }

  
  
  /*Hinweis*/

  #hinweis{
    width: 100%;
  }
  
  .text-hinweis{
    width: 97%;
    margin-right: 25px;
    margin-left: 25px;
    margin-top: 40px;
    margin-bottom: 30px;
    text-align: left;
  }

  .text-hinweisDP{
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    margin-bottom: 30px;
    text-align: justify;
  }


  
  .loading {
    font-size: small;
  }

  /*Responsive Charts*/

  @media (max-width: 1030px) {
    .rowchart .col-9 {
      scale: 0.8;
    }
  }

  @media (max-width: 970px) {
    .rowchart .col-9 {
      scale: 0.75;
      margin-left: -40px;
      margin-top: -40px;
    }
    .buttonschart {
      display: none;
    }
  }

  @media (max-width: 880px) {
    .rowchart .col-2 {
      display: none;
    }
    .rowchart .col-9 {
      margin-left: -70px;
      margin-top: -50px;
    }
  }

  @media (max-width: 750px) {
    .buttonschart {
      display: none;
    }
    .rowchart .col-9 {
      scale: 0.7;
      margin-left: -80px;
      margin-top: -70px;
      margin-bottom: -90px;
    }
  }

  @media (max-width: 680px) {
    .rowchart .col-9 {
      scale: 0.65;
      margin-left: -80px;
    }
  }

  @media (max-width: 635px) {
    .rowchart .col-9 {
      scale: 0.60;
      margin-left: -80px;
    }
    .expbutton {
      display: none;
    }
  }

  @media (max-width: 590px) {
    .rowchart .col-9 {
      scale: 0.6;
      margin-left: -90px;
    }
  }
  