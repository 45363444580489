.buttonmarkiert {
  width: 80%;
  height: 30%;
  outline: grey;
  background-color: rgb(222, 223, 221);
  /* font-size: 0.9vw; */
  align-items: center;

}

.buttonNmarkiert {
  width: 80%;
  height: 30%;
  outline: grey;
  background-color: rgb(162, 158, 158);
  /* font-size: 0.9vw; */
  /* font-weight:bold; */
  align-items: center;
}

.buttonInhalt{
  display: flex;
  flex-direction: row;
}



 /*Button hover text*/
 .hovertextM {
  position: relative;
 
}

.hovertextM:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 140px;
  background-color: rgb(79, 173, 91);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  transition: opacity 1s ease-in-out;
  position: absolute;
  z-index: 1;
  padding: 12%;
  left: -150px;
}

.hovertextM:hover:before {
  opacity: 1;
  visibility: visible;
}