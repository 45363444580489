/* Titel */
#title{
  width: 90%;
  height: 53px;
}

.title{
  position: relative;
  left: 70px;
  
}
.titel-text{
  position: relative;
    left: 35px;
    top: 15px;
    text-align: left;
}
.line{
  background-color: black;
  height: 3px;
  width: 80px;
  position: absolute;
    left: -70px;
    top: 2%;
  margin-right: 20px;
}

/* Hintergrund infos*/

.textbereich {
  box-align: center;
  text-align: left;
  margin-right: 25px;
  margin-left: 25px;
  margin-bottom: 30px;
  margin-top: 25px; 
}
.textbereich a:link{
  color: black;
}
.textbereich a:visited{
  color: black;
}

.texttitle {
  color:rgb(64, 143, 243);
}

/* Erklärung infos*/
.Methodenintro{
  box-align: center;
  text-align: left;
  margin-right: 25px;
  margin-left: 25px;
  margin-bottom: 30px;
  margin-top: 15px; 
}

.nav-tabs {
  padding: 3% 3%;
}

.AuswahlMethoden {
  padding-left: 10%;
  padding-right: 10%;
}

.Knopf {

color: black;
background-color: #d8d8db;

}

.Klapptext {
  margin-top: 5px;
  margin-bottom: 20px;
}

.expl{
  padding: 3% 10%;
}

.Knopf tr.collapse {
  visibility: collapse;
  background-color: #586F7C;
}

.row-background{
  padding: 0;
}

.Gruppen {
  padding-bottom: 2%;
}

.logo {
  height: 4%;
  width: 4%;
  padding-right: 1%;
}
