/* Titel */
#title {
  width: 90%;
}

.title {
  position: relative;
  left: 70px;
}
.titel-text-main {
  position: relative;
  left: 35px;
  text-align: left;
}
.lineheader {
  background-color: black;
  height: 3px;
  width: 80px;
  position: absolute;
  left: -70px;
  top: 27px;
  margin-right: 20px;
}

.expbutton-expon {
  width: 18%;
  position: relative;
  left: 79%;
  margin-right: 5%;
  border-radius: 5px;
  background-color: rgba(104, 162, 239, 0.432);
  border-color: rgba(111, 111, 111, 0.432);
}

.text-expon {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  margin-bottom: 0;
  color: black;
  border-radius: 5px;
}
.text-expon:hover {
  color: black;
}

/* Buttons */

.rowbuttons {
  width: 100%;
  padding: 15px 15px 20px 15px;
  border-radius: 5px;
  text-align: center;
  display: inline-flex;
}

.button {
  outline: black;
  color: black;
  font-size: 1rem;
  border: 1px solid;
  margin: 1px;
}

.button:hover {
  background-color: white;
  color: black;
  transform: scale(1.05);
}

.infobutton {
  border-radius: 5px;
  height: 90px;
}

.infobutton:hover {
  background-color: #ddd;
}

.text-fix {
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
  font-size: 1.1rem;
  margin-bottom: 0;
}
.text-var {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
}

.breit {
  width: 70%;
}

/* Text */
#text {
  width: 100%;
}
.text-intro {
  width: 97%;
  margin-right: 25px;
  margin-left: 25px;
  margin-bottom: 30px;
  text-align: left;
}

/* Chart */
#chart {
  margin-right: 25px;
  margin-left: 25px;
}

.row-chart {
  height: 440px;
}

/*Tabelle*/
#table {
  margin-right: 25px;
  margin-left: 25px;
  margin-bottom: 40px;
  float: left;
  width: 100%;
}

.btn-table {
  float: left;
  margin: 4px;
}

.collapse-table {
  float: left;
  width: 95%;
}

/*Hinweis*/

#hinweis {
  width: 100%;
}

.text-hinweis {
  width: 97%;
  margin-right: 25px;
  margin-left: 25px;
  margin-top: 40px;
  margin-bottom: 30px;
  text-align: left;
}
