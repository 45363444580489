/* Titel */
#title{
  width: 90%;
  height: 53px;
}

.title{
  position: relative;
  left: 70px;
  
}
.titel-text{
  position: relative;
    left: 35px;
    top: 15px;
    text-align: left;
}
.line{
  background-color: black;
  height: 3px;
  width: 80px;
  position: absolute;
    left: -70px;
    top: 27px;
  margin-right: 20px;
}


  
  .expl {
    width: 97%;
    margin-right: 25px;
    margin-left: 25px;
    margin-bottom: 30px;
    margin-top: 5px; 
    text-align: justify;
    font-size: 1.05rem;
    text-align: center;
  }

  /* Kontaktfelder*/

#kontakt {
  padding-left:15%;
  padding-top: 5%;
  padding-bottom: 15%;
  padding-right: 19%;
  background:#F4F4F9;
  overflow:hidden;
}

#Kontaktkarte1 {
  float:left; 
  height: 220px;
  width:40%; 
}

#Kontaktkarte2 {
  float:right; 
  height: 220px;
  width:40%;
}
 #Kontaktkarte1, #Kontaktkarte2 {padding-bottom:0; margin-bottom:0;}


#Kontaktkarte3 {
  float:left;
  height: 140px;
  width:40%; 
}
#Kontaktkarte4 {
  float:right; 
  width:40%;
  height: 140px;
}

 #Kontaktkarte3 {
  padding-bottom:0; padding-top: 8%; margin-bottom:0;
}
  #Kontaktkarte4 {padding-bottom:0; padding-top: 8%; margin-bottom:0;}


.Kontaktkarte {
  width: 440px;
  padding:10px;
  margin-left: 3%;
  margin-right: 3%;
}

p {font-size:1em; line-height:1.3em; margin:0; padding:5px 0;}

.Kontaktdaten {
  border-spacing: 5%;
}

contact_picture {
  height: 200px;
}

a {
  color:inherit;
    text-decoration:none;
}

.contact_sm {
  height: 144px;
}