.GraphEinbettung {
  display: flex;
  position: relative; /* Positionierung relativ für das übergeordnete Element */
}
.StyleMeldedatum {
  position: absolute;
  bottom: 0;
  left: 53%;
  transform: translateX(-50%);
  text-align: center;
}

.loadingNeu {
  width: 70;
  font-size: large;
}

.GraphLabelLeft {
  position: absolute;
  left: 0;
  top: 150%;
  transform: translateY(-50%) rotate(180deg);
  writing-mode: vertical-lr;
  text-align: center;
}
