/* Footer */
 
#footer{
    background-color: black;
    color: white;
  }
  .footer {
    width: 100%;
    height: 2.5rem;
    color: white;
  }

  .footerleft{
    text-align: left;
    padding-left: 4%;
  }
    
 
  .rowfooter{
    margin-top: 120px;
    text-align: left;
    padding: 30px 0px 30px 15px;
    width: 98%;
    
  }
  
  .rowfooter a:link{
    color: white;
  }
  .rowfooter a:visited{
    color: white;
  }
  
  
  .footer-h1{
    font-weight: 600;
    margin-bottom: 60px;
  }
  
  
  footer p {
    font-size: 15px;
  }
  
  .footer_table{
    height: 101%;
    text-align: left;
    margin-right: 5%;
    
  }
  